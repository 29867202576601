#Initial {
	display: flex;
	align-items: flex-end;
    flex-direction: column;
    text-align: center;
    font-family: Helvetica;
}

#InitialCTA {
    text-transform: capitalize;
    margin: auto;
    font-size: 10vh;
    font-family: Helvetica;
    font-weight: 100;
}

#StartButton {
    font-size: 4vh;
    margin: auto;
    margin-bottom: 2vh;
    border-radius: 2vh;
    padding: 1vh;
    width: 15vh;
    box-shadow: 2px 7px 7px #888888;
}