#Title {
    font-size: 3vh;
    order: 1;
}

#Main {
	display: flex;
	align-items: flex-end;
    flex-direction: column;
    text-align: center;
    font-family: Helvetica;
}

#Selectors {
    width : 28vw;
    float: right;
    overflow: auto;
    margin-right: 5px;
    height: 60vh;
    min-width: 400px;
}

.ButtonFlex {
	display: flex;
    flex-wrap: wrap;
}

.SelectButton {
    width: 7vh;
    height: 6vh;
    margin: auto;
    font-size: 2vh;
    font-family: Helvetica;
    font-weight: 100;
    margin:auto;
    margin-bottom: 2vh;
    border-radius: 2vh;
    padding: 1vh;
    width: 15vh;
    text-align: center;
    text-transform: capitalize;
    box-shadow: 2px 7px 7px #888888;
}
.SelectButton img {
    margin: 0;
    height: 4vh;
}

.MenuTitle {
    font-family: Helvetica;
    font-weight: 250;
    text-align: left;
    text-transform: uppercase;
    font-size: 2vh;
}

#MainContent { order:5 }


#DrinkDiv {
    width : 28vw;
    float: left;
    overflow: auto;
    margin-left: 5px;
    height: 60vh;
    min-width: 400px;
}

#SubmitButton {
    order: 10;
    font-size: 4vh;
    margin: auto;
    border-radius: 2vh;
    padding: 1vh;
    width: 15vh;
    box-shadow: 2px 7px 7px #888888;
}