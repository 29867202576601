.App {
  text-align: center;
}
.squared {
    height: 80vh;
    margin: auto;
    width: 60vw;
    background-color: white;
    margin-top: 10vh;
    margin-bottom: 10vh;
    border-radius: 10px;
    border: 1px #eeeeee solid;
    box-shadow: 5px 15px 15px #888888;
    text-overflow: clip;
    min-width: 100vh;
}
